var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.is_mounted
    ? _c("div", [
        _c("div", { attrs: { align: "center" } }, [
          _c("h4", { staticClass: "q-mb-sm" }, [
            _vm._v("Rinnovo pratiche in scadenza"),
          ]),
          _vm._v(" Ufficio: " + _vm._s(_vm.unita_operativa) + " "),
          _c("br"),
          _c("br"),
          _c("hr"),
        ]),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.is_loading,
                expression: "!is_loading",
              },
            ],
            staticClass:
              "text-center justify-center q-pa-md q-gutter-sm fixed-center",
            staticStyle: { "z-index": "1000" },
          },
          [_c("q-spinner-hourglass", { attrs: { size: "4em" } })],
          1
        ),
        _c("div", { staticClass: "q-mr-sm q-ml-sm" }, [
          _c("br"),
          _c("table", { staticClass: "table-bordered" }, [
            _vm._m(0),
            _c(
              "tbody",
              [
                _vm._l(
                  _vm.elenco_contratti_da_lavorare,
                  function (contratto, index) {
                    return _c("tr", { key: index }, [
                      _c(
                        "td",
                        { attrs: { align: "center" } },
                        [
                          contratto.tacito_rinnovo &&
                          !contratto.disdettato &&
                          (contratto.prodotto_id === "rcp_tecnico" ||
                            contratto.prodotto_id === "rcp_medico" ||
                            contratto.prodotto_id === "rcp_sanitario")
                            ? _c("q-btn", {
                                staticStyle: { width: "200px" },
                                attrs: {
                                  color: "deep-orange",
                                  glossy: "",
                                  label: "RINNOVA",
                                },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.onRinnovaRCProfessionale(
                                      contratto
                                    )
                                  },
                                },
                              })
                            : _vm._e(),
                          contratto.tacito_rinnovo &&
                          !contratto.disdettato &&
                          contratto.prodotto_id !== "rcp_tecnico" &&
                          contratto.prodotto_id !== "rcp_medico" &&
                          contratto.prodotto_id !== "rcp_sanitario"
                            ? _c("q-btn", {
                                staticStyle: { width: "200px" },
                                attrs: {
                                  color: "deep-orange",
                                  glossy: "",
                                  label: "RINNOVA",
                                },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.onRinnovaAltriProdotti(contratto)
                                  },
                                },
                              })
                            : _vm._e(),
                          contratto.disdettato
                            ? _c("q-btn", {
                                staticStyle: { width: "200px" },
                                attrs: {
                                  color: "deep-orange",
                                  glossy: "",
                                  label: "CONVALIDA",
                                },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.onConvalidaDisdetta(contratto)
                                  },
                                },
                              })
                            : _vm._e(),
                          !contratto.tacito_rinnovo
                            ? _c("q-btn", {
                                staticStyle: { width: "200px" },
                                attrs: {
                                  color: "positive",
                                  glossy: "",
                                  label: "NUOVO PREVENTIVO",
                                },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.onNuovoPreventivo(contratto)
                                  },
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c("td", [
                        _vm._v(_vm._s(contratto.fornitore)),
                        _c("br"),
                        _vm._v(_vm._s(contratto.operatore)),
                      ]),
                      _c("td", [
                        _vm._v(
                          _vm._s(_vm.nome_contraente(contratto.contraente))
                        ),
                      ]),
                      _c("td", [
                        _vm._v(_vm._s(contratto.compagnia)),
                        _c("br"),
                        _vm._v(_vm._s(contratto.prodotto_id)),
                      ]),
                      _c("td", [
                        _vm._v(_vm._s(_vm.getFrazionamento(contratto))),
                      ]),
                      _c(
                        "td",
                        { attrs: { align: "center" } },
                        [
                          _c("q-checkbox", {
                            attrs: { size: "lg", val: "lg", disable: "" },
                            model: {
                              value: contratto.tacito_rinnovo,
                              callback: function ($$v) {
                                _vm.$set(contratto, "tacito_rinnovo", $$v)
                              },
                              expression: "contratto.tacito_rinnovo",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("td", { attrs: { align: "center" } }, [
                        _vm._v(
                          _vm._s(_vm.contratto_disdettato(contratto.disdettato))
                        ),
                      ]),
                      _c("td", { attrs: { align: "right" } }, [
                        _vm._v(_vm._s(contratto.premioinscadenza) + " Euro"),
                      ]),
                      _c("td", { attrs: { align: "right" } }, [
                        _vm._v(_vm._s(contratto.premio_rinnovo) + " Euro"),
                      ]),
                    ])
                  }
                ),
                _vm.elenco_contratti_da_lavorare.length === 0
                  ? _c("tr", [
                      _c("td", { attrs: { colspan: "9", align: "center" } }, [
                        _vm._v(
                          "NESSUNA PRATICA DA RINNOVARE È STATA IDENTIFICATA"
                        ),
                      ]),
                    ])
                  : _vm._e(),
              ],
              2
            ),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th"),
        _c("th", [_vm._v("Fornitore/Operatore")]),
        _c("th", [_vm._v("Contraente")]),
        _c("th", [_vm._v("Compagnia/Tariffa")]),
        _c("th", [_vm._v("Frazionamento")]),
        _c("th", [_vm._v("Tacito Rinnovo")]),
        _c("th", [_vm._v("Disdettata")]),
        _c("th", [_vm._v("Premio in scadenza")]),
        _c("th", [_vm._v("Premio rinnovo")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }